import Vue from 'vue'
import VueRouter from 'vue-router'
import Booking from '../views/Booking.vue'
import config from '@/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: config.startPage
  },
  {
    path: '/scan/:scanCode',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/scan/:scanCode/bodypart/:bodyPartCode',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/scan/:scanCode/procedure/:procedureCode',
    name: 'Booking',
    component: Booking
  },
  {
    path: '*',
    name: 'Booking',
    component: Booking
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
