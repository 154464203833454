<template>
  <div>
    <v-dialog
      v-model="scheduleDialogOpen"
      @click:outside="closeScheduler"
      :width="$vuetify.breakpoint.smAndDown ? 'auto' : '800'"
    >
      <v-card>
        <v-card-text class="scheduler-background">
          <div
            v-if="schedulerLoading || schedulingAppointment"
            class="text-center pt-12 pb-6"
          >
            <v-progress-circular
              :size="75"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <br /><br />
            <span v-if="schedulerLoading">{{
              $_t('Fetching availability...')
            }}</span>
            <span v-if="schedulingAppointment">{{
              $_t('Scheduling appointment...')
            }}</span>
          </div>
          <div v-else class="pt-5">
            <div class="">
              <v-row class="scheduler-background">
                <v-col cols="12" class="scheduler-background">
                  <span class="title pt-1" style="float:left">{{
                    $_t('Select appointment date')
                  }}</span>
                  <v-btn
                    @click="closeScheduler"
                    color="primary"
                    class="float-right mt-n2"
                    icon
                    x-large
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.smAndDown && alternativeMobileView">
                  <div v-for="(date, index) in datesList" :key="index">
                    <div class="text-h6">
                      {{ date.dateText }}
                    </div>
                    <div v-if="date.slots.length" class="d-flex flex-row flex-wrap">
                      <v-btn
                        v-for="(slot, slotIndex) in date.slots"
                        :key="slotIndex"
                        color="primary"
                        outlined
                        class="mx-1 my-1"
                        @click="scheduleToDateAndTime(date.date, slot.slot_start)"
                      >
                        {{ slot.slot_start.substr(0, 5) }}
                      </v-btn>
                    </div>
                    <div v-else>
                      {{ $_t('No availability') }}
                    </div>
                  </div>
                  <div class="full-width fluid text-center">
                    <v-btn
                      outlined
                      @click="showMore"
                      :loading="loadingMore"
                    >
                      Show more
                    </v-btn>
                  </div>
                </v-col>
                <!-- ---------- Month row mobile ---------- -->
                <v-col
                  v-if="$vuetify.breakpoint.smAndDown && !alternativeMobileView"
                  cols="12"
                  class="text-center primary--text font-weight-bold subtitle-1"
                >
                  <v-row v-if="$vuetify.breakpoint.smAndDown && !alternativeMobileView">
                    <v-col cols="2" class="px-0 pt-5 text-right">
                      <v-btn icon color="primary" @click="prevMonth">
                        <v-icon x-large>mdi-chevron-left</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="8">
                      <p class="mt-3">{{ selectedMonthText }}</p>
                    </v-col>
                    <v-col cols="2" class="px-0 pt-5 text-left">
                      <v-btn icon color="primary" @click="nextMonth">
                        <v-icon x-large>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- ---------- Month row desktop ---------- -->
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  cols="12"
                  class="mb-4 text-center primary--text font-weight-bold subtitle-1"
                >
                  <v-btn
                    icon
                    color="primary"
                    class="mt-n1 mr-6"
                    @click="prevMonth"
                  >
                    <v-icon x-large>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span>{{ selectedMonthText }}</span>
                  <v-btn
                    icon
                    color="primary"
                    class="mt-n1 ml-6"
                    @click="nextMonth"
                  >
                    <v-icon x-large>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
                <!-- ---------- Day row mobile ---------- -->
                <v-col cols="12" class="mb-4">
                  <div
                    v-if="$vuetify.breakpointsm && !alternativeMobileView"
                    class="mb-2 body-1 black--text text-center"
                  >
                    <p>{{ $_t('Week') }} {{ selectedWeekText }}</p>
                  </div>
                  <v-row v-if="$vuetify.breakpointsm && !alternativeMobileView" align="center">
                    <v-col cols="3" class="px-0 pt-5 text-center">
                      <v-btn v-if="prevWeekAvailable" @click="prevWeek" icon color="primary">
                        <v-icon x-large>mdi-chevron-left</v-icon>
                        <br />
                        <span style="font-size: 10px;"
                          >{{ $_t('Prev') }}<br />{{ $_t('week') }}</span
                        >
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="selectedDay"
                        :items="daysMobile"
                        :label="$_t('Select day')"
                        outlined
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="3" class="px-0 pt-5 text-center">
                      <v-btn icon color="primary" @click="nextWeek">
                        <span style="font-size: 10px;"
                          >{{ $_t('Next') }}<br />{{ $_t('week') }}</span
                        >
                        <br />
                        <v-icon x-large>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- ---------- Day row desktop ---------- -->
                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="1">
                      <v-btn
                        v-if="prevWeekAvailable"
                        @click="prevWeek"
                        icon
                        color="primary"
                        class="mt-2"
                      >
                        <v-icon x-large>mdi-chevron-left</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="10">
                      <v-row>
                        <v-col
                          v-for="day in days"
                          v-bind:key="day"
                          class="text-center"
                        >
                          <div
                            style="height: 100%"
                            @click="onDayClicked(day)"
                            v-bind:class="{
                              'scheduler-button': true,
                              'scheduler-button-selected': selectedDay === day,
                              'scheduler-button-disabled': !getNumberOfSlotsInDay(
                                day
                              )
                            }"
                          >
                            {{ getDayLabel(day) }}<br />
                            {{ $_t(getDayLabel(day, 'weekday')) }}<br />
                            {{ getNumberOfSlotsInDay(day) }}
                            {{
                              getNumberOfSlotsInDay(day) === 1
                                ? $_t('slot')
                                : $_t('slots')
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        icon
                        color="primary"
                        class="mt-2"
                        @click="nextWeek"
                      >
                        <v-icon x-large>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- ---------- Time row mobile  ---------- -->
                <v-col cols="12" v-if="selectedDay && timeSlots.length">
                  <v-row v-if="$vuetify.breakpointsm && !alternativeMobileView">
                    <v-col cols="2" class="px-0 pt-2"> </v-col>
                    <v-col cols="8">
                      <div class="mb-2 body-1 black--text">
                        <p>
                          {{ selectedDayTextMobile }}
                          <span style="white-space: nowrap;">
                            {{ availableSlotsText }}</span
                          >
                          <br />
                          {{ howManySlotsText }}
                        </p>
                      </div>
                      <v-select
                        v-model="selectedTimeSlot"
                        :items="timeSlots"
                        :label="$_t('Select time slot')"
                        outlined
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="2" class="px-0 pt-2"> </v-col>
                  </v-row>
                  <!-- ---------- Time row desktop  ---------- -->
                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="1" class="px-0 pt-2"> </v-col>
                    <v-col cols="10">
                      <div
                        class="d-flex mb-2 body-1 black--text"
                        style="justify-content: space-between"
                      >
                        <span>{{ selectedDayTextDesktop }}</span>
                        <span
                          >{{ availableSlotsText }},
                          {{ howManySlotsText }}</span
                        >
                      </div>
                      <div
                        v-for="timeSlot in timeSlots"
                        v-bind:key="timeSlot"
                        @click="onTimeSlotClicked(timeSlot)"
                        class="mb-4 cursor-pointer"
                        style="background-color: #ffffff;"
                        v-bind:style="{
                          border: [
                            selectedTimeSlot === timeSlot
                              ? '3px solid #418fde'
                              : '3px solid #e7f2ff'
                          ],
                          backgroundColor: [
                            selectedTimeSlot === timeSlot
                              ? 'rgb(210 230 255)'
                              : 'white'
                          ]
                        }"
                      >
                        <p
                          class="d-inline-block ma-0 px-8 py-3 body-1 white--text"
                          style="background-color: #72b6ff;"
                        >
                          {{ timeSlot.slice(0, 5) }}
                        </p>
                        <p
                          class="d-inline-block float-right ma-0 px-8 py-3 body-1 primary--text"
                        >
                          {{ $_t('Choose')
                          }}<v-icon class="primary--text"
                            >mdi-chevron-right</v-icon
                          >
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="1" class="px-0 pt-2"> </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="availabilitySearchIterationsLimitReached">
                  <p class="body-1 text-center red--text">
                    <strong>
                      {{ $_t('Could not find any slots within next') }}
                      {{ MAX_AVAILABILITY_SEARCH_ITERATIONS }}
                      {{ $_t('weeks') }}.
                    </strong>
                  </p>
                </v-col>
                <v-col v-else-if="!selectedDay">
                  <p class="body-1 text-center">
                    <strong>{{
                      $_t('Please select date first to view time slots.')
                    }}</strong>
                  </p>
                </v-col>
                <v-col v-else-if="selectedDay && !timeSlots.length && !alternativeMobileView">
                  <p class="body-1 text-center">
                    <strong>{{
                      $_t('No time slots available for the selected date.')
                    }}</strong>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="$vuetify.breakpoint.smAndDown && !alternativeMobileView">
          <v-btn
            @click="closeScheduler"
            width="50%"
            color="primary"
            class="text-none"
            text
            x-large
            >{{ $_t('Cancel') }}</v-btn
          >
          <v-btn
            :disabled="!selectedTimeSlot"
            @click="onScheduleAppointmentClicked"
            width="50%"
            color="primary"
            class="text-none"
            x-large
            >{{ $_t('Confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import config from '@/config';
import { ref, onMounted, computed, watch } from '@vue/composition-api';
import { bookingStore } from '@/store/booking-store';
import rest from '@/plugins/rest';
import Vue from 'vue';
import ROUTE_NAMES from '@/store/consts/route-names';
import moment from 'moment/moment';

const MAX_AVAILABILITY_SEARCH_ITERATIONS = 8;

export default {
  props: ['clinic'],
  name: 'Clinic',
  setup(props, context) {
    let schedulerStartDate = ref(null);
    let schedulerStartTime = ref(null);
    let availability = ref([]);
    let availabilitySearchIterations = ref(0);
    let availabilitySearchIterationsLimitReached = ref(false);
    let selectedDay = ref(null);
    let selectedTimeSlot = ref(null);
    let days = ref([]);
    let timeSlots = ref([]);
    let scheduleDialogOpen = computed(
      () => bookingStore.getState().scheduleDialogOpen
    );
    let schedulerLoading = ref(true);
    let schedulingAppointment = ref(false);
    let selectedBodyPartId = computed(() => bookingStore.getState().bodyPartId);
    let selectedInsurer = computed(() => bookingStore.getState().insurer);
    let selectedClinicId = computed(() => bookingStore.getState().clinicId);
    let selectedClinic = computed(() => bookingStore.getState().clinic);
    let selectedDayTextMobile = computed(() =>
      moment(selectedDay.value).format(config.dateFormat)
    );
    let selectedDayTextDesktop = computed(() => {
      const day = moment(selectedDay.value);
      return (
        day.format('DD ') +
        Vue.prototype.$_t(day.format('MMMM')) +
        day.format(' YYYY') +
        ', ' +
        Vue.prototype.$_t(day.format('dddd'))
      );
    });
    let selectedWeekText = computed(() => {
      const start = moment(days.value[0]);
      const end = moment(days.value[days.value.length - 1]);
      return `${start.format('DD.MM')} - ${end.format(config.dateFormat)}`;
    });
    let selectedMonthText = computed(() => {
      const start = moment(days.value[0]);
      const end = moment(days.value[days.value.length - 1]);
      if (start.format('MM') === end.format('MM')) {
        return (
          Vue.prototype.$_t(`${start.format('MMMM')}`) +
          ' ' +
          `${start.format('YYYY')}`
        );
      } else {
        return (
          Vue.prototype.$_t(`${start.format('MMMM')}`) +
          ' - ' +
          Vue.prototype.$_t(`${end.format('MMMM')}`) +
          ' ' +
          `${end.format('YYYY')}`
        );
      }
    });
    let firstAvailableSlot = computed(() => timeSlots.value[0]);
    let lastAvailableSlot = computed(
      () => timeSlots.value[timeSlots.value.length - 1]
    );
    let availableSlotsText = computed(
      () => `${Vue.prototype.$_t('Available slots')}: ${timeSlots.value.length}`
    );
    let howManySlotsText = computed(
      () =>
        `${firstAvailableSlot.value.substring(
          0,
          5
        )} - ${lastAvailableSlot.value.substring(0, 5)}`
    );
    let daysMobile = computed(() => {
      const _days = [];
      days.value.forEach((day) => {
        const slots = getNumberOfSlotsInDay(day);
        const dayMoment = moment(day);
        const dateFormatted =
          Vue.prototype.$_t(dayMoment.format('ddd')) +
          moment(day).format(', DD.MM');
        const text = `${dateFormatted} (${slots} ${
          slots === 1 ? Vue.prototype.$_t('slot') : Vue.prototype.$_t('slots')
        })`;
        const value = day;
        const disabled = slots === 0;
        _days.push({
          text,
          value,
          disabled
        });
      });
      return _days;
    });
    let prevWeekAvailable = computed(() => {
      const currentDate = moment(schedulerStartDate.value);
      const prevWeek = currentDate.clone().subtract(1, 'w');
      const startOfPrevWeek = prevWeek.clone().startOf('week');
      const today = moment();
      return today.diff(startOfPrevWeek) < 0;
    });

    const init = async () => {
      schedulerStartDate.value = selectedClinic.value.availability_date;
      schedulerStartTime.value = selectedClinic.value.availability_start;
      showClinicsSlots();
    };

    onMounted(init);

    watch(selectedDay, () => {
      timeSlots.value = Object.keys(availability.value[selectedDay.value]);
      selectedTimeSlot.value = null;
    });

    const showClinicsSlots = async () => {
      schedulerLoading.value = true;
      const _availability = await getClinicSlots(
        selectedInsurer.value.insurer_id,
        selectedClinicId.value,
        selectedClinic.value.availability_date, //min date, from clinic
        schedulerStartTime.value, //min time, from clinic, when we switch dates it is set to 00:00:00
        schedulerStartDate.value //app date, current week in scheduler
      );
      if (!_availability) {
        Vue._notify.error('Failed to fetch slots of a clinic');
        return;
      }
      availability.value = _availability;
      days.value = Object.keys(_availability);
      schedulerLoading.value = false;
    };

    const getNumberOfSlotsInDay = (day) => {
      const slotsInDayObj = availability.value[day];
      const slotsArr = Object.values(slotsInDayObj);
      return slotsArr.length;
    };

    const prevMonth = () => {
      const start = moment(days.value[0]);
      const firstDayOfMonth = start.clone().startOf('month');
      const lastDayNextMonth = firstDayOfMonth.clone().subtract(1, 'days');
      schedulerStartDate.value = lastDayNextMonth.format('YYYY-MM-DD');
      schedulerStartTime.value = '00:00:00';
      availabilitySearchIterationsLimitReached.value = false;
      showClinicsSlots();
    };

    const nextMonth = () => {
      const end = moment(days.value[days.value.length - 1]);
      const lastDayOfMonth = end.clone().endOf('month');
      const fistDayNextMonth = lastDayOfMonth.clone().add(1, 'days');
      schedulerStartDate.value = fistDayNextMonth.format('YYYY-MM-DD');
      schedulerStartTime.value = '00:00:00';
      availabilitySearchIterationsLimitReached.value = false;
      showClinicsSlots();
    };

    const prevWeek = async () => {
      const currentDate = moment(schedulerStartDate.value);
      const prevWeek = currentDate.subtract(1, 'w');

      schedulerStartDate.value = prevWeek.format('YYYY-MM-DD');
      schedulerStartTime.value = moment(
        selectedClinic.value.availability_date
      ).isSame(prevWeek)
        ? selectedClinic.value.availability_start
        : '00:00:00';
      availabilitySearchIterationsLimitReached.value = false;

      await showClinicsSlots();

      if (prevWeekAvailable.value) {
        await handlePrevWeekWhenNoSlots();
      }
    };

    const nextWeek = async () => {
      const currentDate = moment(schedulerStartDate.value);
      const nextWeek = currentDate.add(1, 'w');

      schedulerStartDate.value = nextWeek.format('YYYY-MM-DD');
      schedulerStartTime.value = moment(
        selectedClinic.value.availability_date
      ).isSame(nextWeek)
        ? selectedClinic.value.availability_start
        : '00:00:00';
      availabilitySearchIterationsLimitReached.value = false;

      await showClinicsSlots();
      await handleNextWeekWhenNoSlots();
    };

    const handlePrevWeekWhenNoSlots = async () => {
      const availableSlots = Object.values(availability.value)
        .map((o) => Object.keys(o).length)
        .reduce((a, b) => a + b, 0);
      if (availableSlots === 0 && availabilitySearchIterations.value < MAX_AVAILABILITY_SEARCH_ITERATIONS) {
        availabilitySearchIterations.value++;
        await prevWeek();
      }
      if (availabilitySearchIterations.value === MAX_AVAILABILITY_SEARCH_ITERATIONS && availableSlots === 0) {
        availabilitySearchIterationsLimitReached.value = true;
      }
      if (availabilitySearchIterations.value === MAX_AVAILABILITY_SEARCH_ITERATIONS || availableSlots > 0) {
        availabilitySearchIterations.value = 0;
      }
    }

    const handleNextWeekWhenNoSlots = async () => {
      const availableSlots = Object.values(availability.value)
        .map((o) => Object.keys(o).length)
        .reduce((a, b) => a + b, 0);
      if (availableSlots === 0 && availabilitySearchIterations.value < MAX_AVAILABILITY_SEARCH_ITERATIONS) {
        availabilitySearchIterations.value++;
        await nextWeek();
      }
      if (availabilitySearchIterations.value === MAX_AVAILABILITY_SEARCH_ITERATIONS && availableSlots === 0) {
        availabilitySearchIterationsLimitReached.value = true;
      }
      if (availabilitySearchIterations.value === MAX_AVAILABILITY_SEARCH_ITERATIONS || availableSlots > 0) {
        availabilitySearchIterations.value = 0;
      }
    }

    const setDay = (day) => {
      selectedDay.value = day;
    };

    const setTimeSlot = (timeSlot) => {
      selectedTimeSlot.value = timeSlot;
    };

    const getDayLabel = (day, type) => {
      const date = moment(day);
      if (type === 'weekday') {
        return date.format('ddd');
      } else {
        return Vue.prototype.$_t(date.format('MMM')) + ' ' + date.format('DD');
      }
    };

    const closeScheduler = () => {
      bookingStore.setClinicId(null);
      bookingStore.setClinic(null);
      bookingStore.setScheduleDialogOpen(false);
    };

    const onDayClicked = (day) => {
      if (!getNumberOfSlotsInDay(day)) {
        return;
      }
      setDay(day);
    };

    const onTimeSlotClicked = async (timeSlot) => {
      setTimeSlot(timeSlot);
      if (!context.root.$vuetify.breakpoint.smAndDown) {
        await scheduleAppointment();
      }
    };

    const onScheduleAppointmentClicked = async () => {
      await scheduleAppointment();
    };

    const getClinicSlots = async (
      insurerId,
      clinicId,
      appointmentDate_Min,
      appointmentTime_Min,
      appointmentDate
    ) => {
      let availability = null;
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        const availabilityResponse = await rest.get(
          bookingStore.getState().apiUrls.getSlotsUrl,
          {
            bodyPartId: selectedBodyPartId.value || '',
            insurerId: insurerId,
            clinicId: clinicId,
            appointmentDate_Min: appointmentDate_Min,
            appointmentTime_Min: appointmentTime_Min,
            appointmentDate: appointmentDate,
            days: context.root.$vuetify.breakpoint.smAndDown && alternativeMobileView.value ? 7 : ''
          }
        );
        if (availabilityResponse.status === 'S') {
          availability = availabilityResponse.response.availability;
        }
      } catch {}
      return availability;
    };

    const   scheduleAppointment = async () => {
      schedulingAppointment.value = true;
      const slotInfo =
        availability.value[selectedDay.value][selectedTimeSlot.value];
      const time = selectedTimeSlot.value

      try {
        const scheduleAppointmentResponse = await rest.get(
          bookingStore.getState().apiUrls.scheduleAppointmentUrl,
          {
            bodyPartId: selectedBodyPartId.value || '',
            clinicScanRoomId: slotInfo.clinic_scan_room_id,
            slotDate: selectedDay.value,
            slotTime: time,
            slotDuration: slotInfo.procedure_interval_generic
          }
        );

        if (scheduleAppointmentResponse.status !== 'S') {
          Vue._notify.error('Failed to schedule an appointment.');
          return;
        }

        if (scheduleAppointmentResponse.response.status) {
          // scheduled
          Vue._notify.success('Time slot successfully booked.');
          bookingStore.setSlot({
            date: selectedDay.value,
            time: selectedTimeSlot.value
          });

          bookingStore.setScheduleDialogOpen(false);
          context.root.$router.push(ROUTE_NAMES.patientForm);
        } else {
          // slot taken
          Vue._notify.error(
            'This slot was already booked. Please pick a new slot.'
          );
          schedulingAppointment.value = false;
          await getClinicSlots(
            selectedInsurer.value.insurer_id,
            selectedClinicId.value,
            selectedClinic.value.availability_date, //min date, from clinic
            schedulerStartTime.value, //min time, from clinic, when we switch dates it is set to 00:00:00
            schedulerStartDate.value //app date, current week in scheduler
          );
        }
      } catch (e) {
        Vue._notify.error('Failed to schedule an appointment.');
        schedulingAppointment.value = false;
      }
    };

    const alternativeMobileView = ref(config.scheduleAlternativeMobileView)

    const datesList = computed(() => {
      const list = {}
      if (selectedClinic.value.availability_date) {
        const startDateParts = selectedClinic.value.availability_date.split('-')
        const date = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2], 0, 0, 0, 0)

        const len = typeof availability.value !== 'undefined' && Object.keys(availability.value).length > 0 ? Object.keys(availability.value).length : 6

        for (let i = 0; i < len; i++) {
          const dateUnformatted = moment(date).format('YYYY-MM-DD')

          list[dateUnformatted] = {
            dateText: moment(date).format('MMM DD, dddd'),
            date: dateUnformatted,
            slots: typeof availability.value[dateUnformatted] !== 'undefined' ? Object.values(availability.value[dateUnformatted]) : []
          }
          date.setHours(24)
        }
      }

      return list
    })

    const scheduleToDateAndTime = async (date, time) => {
      setDay(date)
      setTimeSlot(time);
      await scheduleAppointment();
    }

    const loadingMore = ref(false)

    const showMore = async () => {
      const lastDay = Object.keys(datesList.value).pop()
      if (lastDay) {
        const lastDayParts = lastDay.split('-')
        const newDate = new Date(lastDayParts[0], lastDayParts[1] - 1, lastDayParts[2], 0, 0, 0, 0)
        newDate.setHours(24)
        loadingMore.value = true
        const _availability = await getClinicSlots(
            selectedInsurer.value.insurer_id,
            selectedClinicId.value,
            moment(newDate).format('YYYY-MM-DD'), //min date, from clinic
            schedulerStartTime.value, //min time, from clinic, when we switch dates it is set to 00:00:00
            moment(newDate).format('YYYY-MM-DD') //app date, current week in scheduler
        )
        loadingMore.value = false
        availability.value = {...availability.value, ..._availability}
      }
    }

    return {
      MAX_AVAILABILITY_SEARCH_ITERATIONS,
      config,
      scheduleDialogOpen,
      schedulerLoading,
      schedulingAppointment,
      getNumberOfSlotsInDay,
      prevMonth,
      nextMonth,
      prevWeek,
      nextWeek,
      prevWeekAvailable,
      availabilitySearchIterations,
      availabilitySearchIterationsLimitReached,
      selectedDay,
      selectedDayTextMobile,
      selectedDayTextDesktop,
      selectedWeekText,
      selectedMonthText,
      selectedTimeSlot,
      availableSlotsText,
      howManySlotsText,
      days,
      daysMobile,
      timeSlots,
      onDayClicked,
      onTimeSlotClicked,
      onScheduleAppointmentClicked,
      getDayLabel,
      closeScheduler,
      scheduleAppointment,
      alternativeMobileView,
      datesList,
      scheduleToDateAndTime,
      showMore,
      loadingMore
    };
  }
};
</script>
<style scoped>
.scheduler-background {
  background-color: #e7f2ff;
}
.scheduler-button {
  padding: 5px 0;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgb(114, 182, 255);
}
.scheduler-button-selected {
  background-color: #0078ff;
}
.scheduler-button-disabled {
  background-color: #98bee9;
  cursor: not-allowed;
}
</style>
