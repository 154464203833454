<template>
  <div id="app">
    <v-app>
      <router-view :key="$route.name"/>
      <notifications group="booking" />
    </v-app>
  </div>
</template>

<script>
  import config from '@/config'

  export default {
    setup() {
      const initGoogle = () => {
          (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', config.googleGTM);
      }

      if (config.googleGTM) {
        initGoogle()
      }

    }
  }
</script>
